import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Logo'
import { Stack, StackProps } from 'rsuite'

const Brand = (stackProps: StackProps) => {
  return (
    <Stack className="brand" {...stackProps}>
      <Logo height={26} width={26} style={{ marginTop: 6 }} />
      <Link to="/">
        <div className="flex flex-col" style={{ marginLeft: '14px' }}>
          <span>LUNI.ai</span>
          <span>Meeting Intelligence</span>
        </div>
      </Link>
    </Stack>
  )
}

export default Brand
