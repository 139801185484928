import { useCallback, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export function guessStorageAddress() {
  const host = window.location.host
  if (host.toLowerCase().indexOf('localhost') > -1) {
    return 'http://localhost:4050'
  } else if (host.toLowerCase().startsWith('app.')) {
    return `https://storage.${host.replace(/^app\./i, '')}`
  } else {
    return `https://storage.${host}`
  }
}

export function getStorageUrl(fileId: string, accessToken?: string) {
  return (
    guessStorageAddress() +
    `/files/${fileId}${accessToken ? `?accessToken=${accessToken}` : ''}`
  )
}

const locks: Record<string, boolean> = {}

export function useStorageDataUrl() {
  const [data, setData] = useState<Record<string, string | undefined>>({})
  const [error, setError] = useState<Error>()
  const { getAccessTokenSilently } = useAuth0()

  async function ftch(fileId: string) {
    try {
      const accessToken = await getAccessTokenSilently()
      const response = await fetch(getStorageUrl(fileId), {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      const blob = await response.blob()
      const dataUrl = await toDataUrl(blob)

      if (!dataUrl) {
        return
      }

      setData((prevData) => ({
        ...prevData,
        [fileId]: dataUrl,
      }))
    } catch (err) {
      setError(err as Error)
    }
  }

  const fetchFile = useCallback(
    (fileId: string) => {
      if (fileId in data) {
        return data[fileId]
      } else {
        if (fileId in locks) {
          return
        }

        locks[fileId] = true
        ftch(fileId).finally(() => {
          delete locks[fileId]
        })
        return
      }
    },
    [data, ftch]
  )

  return {
    error,
    fetchFile,
  }
}

function toDataUrl(blob: Blob) {
  return new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result?.toString())
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
