import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  Button,
  FlexboxGrid,
  Stack,
  IconButton,
  List,
  Modal,
  Loader,
} from 'rsuite'
import stringSimilarity from 'string-similarity'
import {
  GetMeetingParticipantsQuery,
  useGetMeetingParticipantsQuery,
} from '../../generated/urql.user'
import {
  PiFileAudioLight,
  PiFileVideoLight,
  PiPlusCircleLight,
  PiUploadLight,
  PiX,
} from 'react-icons/pi'
import { useAuth0 } from '@auth0/auth0-react'
import { guessStorageAddress } from '../../lib/storage'

export type UploadRecordingsModalType = {
  show: (meetingId: string) => void
}

type MeetingAudioAssignedFile = { type: 'meeting:audio'; file: File }
type MeetingVideoAssignedFile = { type: 'meeting:video'; file: File }
type ParticipantAudioAssignedFile = {
  type: 'participant:audio'
  participant: GetMeetingParticipantsQuery['meeting_participant'][number]
  file: File
}
type AssignedFile =
  | MeetingVideoAssignedFile
  | MeetingAudioAssignedFile
  | ParticipantAudioAssignedFile

const UploadRecordingsModal = forwardRef<UploadRecordingsModalType>(
  (props, ref) => {
    const [open, setOpen] = useState(false)
    const [meetingId, setMeetingId] = useState<string>()
    const [{ data: getMeetingParticipantsData }] =
      useGetMeetingParticipantsQuery({
        variables: { meetingId },
        pause: !meetingId,
      })
    const [assignedFiles, setAssignedFiles] = useState<AssignedFile[]>([])
    const { getAccessTokenSilently } = useAuth0()
    const [uploading, setUploading] = useState(false)

    useImperativeHandle(ref, () => ({
      show: (meetingId) => {
        setMeetingId(meetingId)
        setAssignedFiles([])
        setOpen(true)
      },
    }))

    const handleFiles = useCallback(
      async (files: FileList) => {
        const participants =
          getMeetingParticipantsData?.meeting_participant || []
        const participantNames = participants.map(
          ({ profile, externalId }) => profile?.name || externalId
        )

        const newAssignedFiles = assignedFiles.slice()

        for (let i = 0; i < files.length; i++) {
          const file = files[i]

          if (file.name.match(/.mp4$/i)) {
            const fileIdx = newAssignedFiles.findIndex(
              (assignedFile) => assignedFile.type === 'meeting:video'
            )
            if (fileIdx !== -1) newAssignedFiles.splice(fileIdx, 1)
            newAssignedFiles.push({ type: 'meeting:video', file })
          } else if (
            file.name
              .replace(/^audio/, '')
              .replace(/[0-9]+\.m4a$/, '')
              .trim()
          ) {
            const participantNameFromFilename = file.name
              .replace(/^audio/, '')
              .replace(/[0-9]+\.m4a$/, '')
              .trim()

            const bestMatch = stringSimilarity.findBestMatch(
              participantNameFromFilename,
              participantNames
            )

            const fileIdx = newAssignedFiles.findIndex(
              (assignedFile) =>
                assignedFile.type === 'participant:audio' &&
                assignedFile.participant.id ===
                  participants[bestMatch.bestMatchIndex].id
            )
            if (fileIdx !== -1) newAssignedFiles.splice(fileIdx, 1)
            newAssignedFiles.push({
              type: 'participant:audio',
              participant: participants[bestMatch.bestMatchIndex],
              file,
            })
          } else if (file.name.match(/^audio[0-9]+\.m4a$/)) {
            const fileIdx = newAssignedFiles.findIndex(
              (assignedFile) => assignedFile.type === 'meeting:audio'
            )
            if (fileIdx !== -1) newAssignedFiles.splice(fileIdx, 1)
            newAssignedFiles.push({ type: 'meeting:audio', file })
          }
        }
        setAssignedFiles(newAssignedFiles)
      },
      [
        assignedFiles,
        setAssignedFiles,
        getMeetingParticipantsData?.meeting_participant,
      ]
    )

    const doUploadFiles = useCallback(async () => {
      setUploading(true)

      try {
        const formData = new FormData()

        assignedFiles.forEach((assignedFile, i) => {
          formData.append(`file${i}`, assignedFile.file)
          formData.append(`type${i}`, assignedFile.type)
          if (assignedFile.type === 'participant:audio') {
            formData.append(
              `participantId${i}`,
              assignedFile.participant.id.toString()
            )
          }
        })

        const response = await fetch(
          `${guessStorageAddress()}/meetings/${meetingId}/recordings`,
          {
            method: 'POST',
            body: formData,
            headers: {
              authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          }
        )
      } catch (e) {
        console.error(e)
      } finally {
        setUploading(false)
      }

      setOpen(false)
    }, [meetingId, assignedFiles, getAccessTokenSilently])

    const videoAssignedFile = assignedFiles.find(
      ({ type }) => type === 'meeting:video'
    )
    const audioAssignedFile = assignedFiles.find(
      ({ type }) => type === 'meeting:audio'
    )

    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload meeting recordings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List>
            {getMeetingParticipantsData?.meeting_participant.map(
              (participant) => {
                const assignedFile = assignedFiles.find(
                  (assignedFile) =>
                    assignedFile.type === 'participant:audio' &&
                    assignedFile.participant.id === participant.id
                )

                return (
                  <List.Item>
                    <FlexboxGrid>
                      <FlexboxGrid.Item colspan={10} className="px-3 py-2">
                        <span>
                          Audio{' '}
                          <span className="text-muted text-xs">
                            (
                            {participant.profile?.name ||
                              participant.externalId}
                            )
                          </span>
                          <span className="text-red-600" title="Mandatory file">
                            &nbsp;*
                          </span>
                        </span>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item colspan={14}>
                        {assignedFile && (
                          <Stack
                            spacing={4}
                            alignItems="center"
                            className="px-3 py-2"
                          >
                            <PiFileAudioLight
                              className="inline-block"
                              size={20}
                            />
                            <span className="text-xs text-muted">
                              {assignedFile.file.name}
                            </span>
                            <IconButton
                              icon={<PiX color="red" />}
                              size="xs"
                              style={{ padding: '.25rem .2rem' }}
                              onClick={() => {
                                setAssignedFiles(
                                  assignedFiles.filter(
                                    (assignedFile) =>
                                      !(
                                        assignedFile.type ===
                                          'participant:audio' &&
                                        assignedFile.participant.id ===
                                          participant.id
                                      )
                                  )
                                )
                              }}
                            />
                          </Stack>
                        )}
                        {!assignedFile && (
                          <div>
                            <input
                              id={`file-input-${participant.id}`}
                              type="file"
                              className="hidden"
                              multiple
                              accept="audio/*,video/*"
                              onChange={(event) => {
                                if (event.target.files) {
                                  if (event.target.files.length === 1) {
                                    setAssignedFiles([
                                      ...assignedFiles,
                                      {
                                        type: 'participant:audio',
                                        file: event.target.files[0],
                                        participant,
                                      },
                                    ])
                                  } else {
                                    handleFiles(event.target.files).catch(
                                      console.error
                                    )
                                  }
                                }
                              }}
                            />
                            <Button
                              appearance="link"
                              onClick={() =>
                                document
                                  .getElementById(
                                    `file-input-${participant.id}`
                                  )
                                  ?.click()
                              }
                            >
                              <PiPlusCircleLight />
                              &nbsp;Add file
                            </Button>
                          </div>
                        )}
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </List.Item>
                )
              }
            )}
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={10} className="px-3 py-2">
                  <span>Video</span>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>
                  {videoAssignedFile && (
                    <Stack
                      spacing={4}
                      alignItems="center"
                      className="px-3 py-2"
                    >
                      <PiFileVideoLight className="inline-block" size={20} />
                      <span className="text-xs text-muted">
                        {videoAssignedFile.file.name}
                      </span>
                      <IconButton
                        icon={<PiX color="red" />}
                        size="xs"
                        style={{ padding: '.25rem .2rem' }}
                        onClick={() => {
                          setAssignedFiles(
                            assignedFiles.filter(
                              (assignedFile) =>
                                assignedFile !== videoAssignedFile
                            )
                          )
                        }}
                      />
                    </Stack>
                  )}
                  {!videoAssignedFile && (
                    <>
                      <input
                        id={`file-input-video`}
                        type="file"
                        className="hidden"
                        multiple
                        accept="audio/*,video/*"
                        onChange={(event) => {
                          if (event.target.files) {
                            if (event.target.files.length === 1) {
                              setAssignedFiles([
                                ...assignedFiles,
                                {
                                  type: 'meeting:video',
                                  file: event.target.files[0],
                                },
                              ])
                            } else {
                              handleFiles(event.target.files).catch(
                                console.error
                              )
                            }
                          }
                        }}
                      />
                      <Button
                        appearance="link"
                        onClick={() =>
                          document.getElementById(`file-input-video`)?.click()
                        }
                      >
                        <PiPlusCircleLight />
                        &nbsp;Add file
                      </Button>
                    </>
                  )}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={10} className="px-3 py-2">
                  <span>
                    Audio <span className="text-muted text-xs">(mixed)</span>
                  </span>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>
                  {audioAssignedFile && (
                    <Stack
                      spacing={4}
                      alignItems="center"
                      className="px-3 py-2"
                    >
                      <PiFileAudioLight className="inline-block" size={20} />
                      <span className="text-xs text-muted">
                        {audioAssignedFile.file.name}
                      </span>
                      <IconButton
                        icon={<PiX color="red" />}
                        size="xs"
                        style={{ padding: '.25rem .2rem' }}
                        onClick={() => {
                          setAssignedFiles(
                            assignedFiles.filter(
                              (assignedFile) =>
                                assignedFile !== audioAssignedFile
                            )
                          )
                        }}
                      />
                    </Stack>
                  )}
                  {!audioAssignedFile && (
                    <>
                      <input
                        id={`file-input-audio`}
                        type="file"
                        className="hidden"
                        multiple
                        accept="audio/*,video/*"
                        onChange={(event) => {
                          if (event.target.files) {
                            if (event.target.files.length === 1) {
                              setAssignedFiles([
                                ...assignedFiles,
                                {
                                  type: 'meeting:audio',
                                  file: event.target.files[0],
                                },
                              ])
                            } else {
                              handleFiles(event.target.files).catch(
                                console.error
                              )
                            }
                          }
                        }}
                      />
                      <Button
                        appearance="link"
                        onClick={() =>
                          document.getElementById(`file-input-audio`)?.click()
                        }
                      >
                        <PiPlusCircleLight />
                        &nbsp;Add file
                      </Button>
                    </>
                  )}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
          </List>

          <p className={`text-xs text-muted px-3 py-2`}>
            Tip: if you add multiple files at once, we'll try to automatically
            assign them!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="d-flex align-items-center"
            onClick={doUploadFiles}
            appearance="link"
            disabled={
              uploading /*||
              assignedFiles.filter(({ type }) => type === 'participant:audio')
                .length !==
                getMeetingParticipantsData?.meeting_participant?.length */
            }
          >
            {uploading && <Loader />}
            {!uploading && <PiUploadLight size={20} className="me-1" />}
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
)

export default UploadRecordingsModal
