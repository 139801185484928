import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { Avatar, Button, DatePicker, Form, Modal, Tag, TagPicker } from 'rsuite'
import { Controller, useForm } from 'react-hook-form'
import {
  Integration_Type_Enum,
  useGetProfilesSubscription,
  useInsertCustomMeetingMutation,
} from '../../generated/urql.user'
import TextArea from 'antd/es/input/TextArea'
import { getStorageUrl } from '../../lib/storage'
import { useAuth0 } from '@auth0/auth0-react'
import { ItemDataType } from 'rsuite/esm/@types/common'

export type AddMeetingModalType = {
  show: () => void
}

type MeetingAddForm = {
  topic: string
  actualStart?: Date
  actualDuration: number
  description?: string
  participantIds: string[]
}

const AddMeetingModal = forwardRef<
  AddMeetingModalType,
  { onMeetingCreated?: (meetingId: string) => void }
>(({ onMeetingCreated }, ref) => {
  const [open, setOpen] = useState(false)
  const [{ data: profilesData }] = useGetProfilesSubscription()
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>()
  const [{ fetching: insertCustomMeetingFetching }, insertCustomMeeting] =
    useInsertCustomMeetingMutation()
  const { control, handleSubmit } = useForm<MeetingAddForm>({
    defaultValues: {
      topic: '',
      description: undefined,
      participantIds: [],
    },
  })

  useImperativeHandle(ref, () => ({
    show: () => {
      setOpen(true)
    },
  }))

  useEffect(() => {
    let stop = false
    getAccessTokenSilently().then((accessToken) => {
      if (!stop) {
        setAccessToken(accessToken)
      }
    })

    return () => {
      stop = true
    }
  }, [getAccessTokenSilently, setAccessToken])

  const participantOptions: ItemDataType[] = useMemo(
    () =>
      profilesData?.profile.map((profile) => ({
        label: profile.name,
        value: profile.id,
        group: profile.email ? 'Registered' : 'Anonymous',
        pictureUrl:
          profile.pictureFileId && accessToken
            ? getStorageUrl(profile.pictureFileId, accessToken)
            : undefined,
      })) || [],
    [profilesData, accessToken]
  )

  const doCreateMeeting = async (data: MeetingAddForm) => {
    try {
      const { data: insertCustomMeetingData, error } =
        await insertCustomMeeting({
          topic: data.topic,
          description: data.description,
          externalId: Math.random().toString(36),
          participants: data.participantIds.map((profileIdOrName) => {
            const profile = profilesData?.profile.find(
              ({ id }) => id === profileIdOrName
            )
            const externalId = Math.random().toString(36)
            return profile
              ? { profileId: profileIdOrName, externalId }
              : {
                  externalId,
                  profile: {
                    data: {
                      name: profileIdOrName,
                      integrationType: Integration_Type_Enum.None,
                      externalId,
                      profileStem: { data: {} },
                    },
                  },
                }
          }),
        })

      if (error) {
        console.error(error)
        return
      }

      if (onMeetingCreated && insertCustomMeetingData?.insert_meeting_one?.id) {
        setOpen(false)
        onMeetingCreated(insertCustomMeetingData.insert_meeting_one.id)
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (!accessToken) return null

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Create meeting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <Form.Group controlId="topic">
            <Form.ControlLabel>Topic</Form.ControlLabel>
            <Controller
              name="topic"
              control={control}
              rules={{ required: 'Topic is required' }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Form.Control
                  name="topic"
                  placeholder="Meeting topic"
                  errorMessage={error?.message}
                  errorPlacement="bottomStart"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <Form.HelpText>Required</Form.HelpText>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.ControlLabel>Description</Form.ControlLabel>
            <Controller
              name="description"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Form.Control
                  rows={5}
                  name="description"
                  errorMessage={error?.message}
                  errorPlacement="bottomStart"
                  onChange={onChange}
                  value={value}
                  accepter={TextArea}
                  placeholder="Meeting description"
                />
              )}
            />
          </Form.Group>
          <Form.Group controlId="participantIds">
            <Form.ControlLabel>Participants</Form.ControlLabel>
            <Controller
              name="participantIds"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TagPicker
                  creatable
                  block
                  groupBy="group"
                  sort={(isGroup) => (a, b) =>
                    isGroup
                      ? a === 'Registered'
                        ? 1
                        : -1
                      : a.label.localeCompare(b.label)}
                  renderMenuItem={(label, item) => {
                    return (
                      <>
                        <Avatar
                          className="rs-icon"
                          size="xs"
                          style={{ padding: '0' }}
                          circle
                          src={item?.pictureUrl}
                        />{' '}
                        {label}
                      </>
                    )
                  }}
                  /* @ts-ignore */
                  renderValue={(
                    values: string[],
                    items: typeof participantOptions
                  ) => {
                    return items.map((item) => (
                      <Tag key={item?.value}>
                        <Avatar
                          className="rs-icon"
                          size="xs"
                          style={{ padding: '0' }}
                          circle
                          src={item?.pictureUrl}
                        />{' '}
                        {item.label}
                      </Tag>
                    ))
                  }}
                  onCreate={(_, item) => {
                    item.group = 'New'
                  }}
                  onChange={onChange}
                  value={value}
                  data={participantOptions}
                />
              )}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="d-flex align-items-center"
          appearance="primary"
          loading={insertCustomMeetingFetching}
          disabled={insertCustomMeetingFetching}
          onClick={handleSubmit(doCreateMeeting)}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default AddMeetingModal
