import React, {
  CSSProperties,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
} from 'react'
import './styles.less'

type ItemProps = React.PropsWithChildren<{
  time: number
  className?: string
  style?: CSSProperties
}>

export const Item: React.FC<ItemProps> = ({ children, className, style }) => {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  )
}

function isItemElement(
  element: ReactNode
): element is React.ReactElement<ItemProps> {
  return (
    !!element &&
    typeof element === 'object' &&
    'props' in element &&
    'time' in element.props
  )
}

export type EventsTimelineProps = {
  children: ReactNode
  style?: CSSProperties
  className?: string
}

export type EventsTimelineRef = {
  scrollTo: (time: number) => void
}

const EventsTimelineComponent = forwardRef<
  EventsTimelineRef,
  EventsTimelineProps
>(({ children, className, style }, ref) => {
  const ulRef = useRef<HTMLUListElement | null>(null)
  const childrenArr = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  )

  useImperativeHandle(ref, () => ({
    scrollTo: (time: number) => {
      if (childrenArr.length === 0) {
        return
      }

      const greaterItemIdx = childrenArr.findIndex(
        (child) => isItemElement(child) && child.props.time > time
      )

      const scrollToChildIdx =
        greaterItemIdx === -1
          ? childrenArr.length - 1
          : greaterItemIdx === 0
          ? 0
          : greaterItemIdx - 1

      const liEl = ulRef.current?.children[scrollToChildIdx]

      liEl?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      liEl?.classList.add('bg-orange-100')
      setTimeout(() => liEl?.classList.remove('bg-orange-100'), 1000)
    },
  }))

  return (
    <div className="events-timeline">
      <ul className={className} style={style} ref={ulRef}>
        {childrenArr.map((child, i) => (
          <li
            key={i}
            style={{
              transition: 'background-color 0.5s ease',
            }}
          >
            {child}
          </li>
        ))}
      </ul>
    </div>
  )
})

export const EventsTimeline = Object.assign(EventsTimelineComponent, { Item })
