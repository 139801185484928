import { Dropdown, IconButton, Loader, Popover, Stack, Whisper } from 'rsuite'
import { CiEdit, CiSettings, CiSquareCheck, CiTrash } from 'react-icons/ci'
import React from 'react'
import {
  Content_Type_Enum,
  useDeleteMeetingPromptOutputMutation,
  useGetCenterAnalysisMeetingPromptOutputsSubscription,
} from '../../generated/urql.user'
import { parsePromptOutput } from '../../lib/prompt/output'

export const MeetingPromptOutputs: React.FC<{
  meetingId: string
  onEdit?: ({
    promptId,
    meetingId,
  }: {
    promptId: string
    meetingId: string
  }) => void
}> = ({ meetingId, onEdit }) => {
  const [{ data: GetCenterAnalysisMeetingPromptOutputsData }] =
    useGetCenterAnalysisMeetingPromptOutputsSubscription({
      variables: { meetingId },
    })
  const [, deleteMeetingPromptOutput] = useDeleteMeetingPromptOutputMutation()
  const meetingPromptOutputs =
    GetCenterAnalysisMeetingPromptOutputsData?.meeting_prompt_output

  if (!meetingPromptOutputs) {
    return <Loader />
  }

  return (
    <>
      {meetingPromptOutputs.map(({ output, prompt, finishedAt }) => (
        <Stack key={prompt.id} alignItems="flex-start" className="mb-4">
          <Stack.Item grow={0} shrink={0} basis="130px" className="pr-3">
            <h5 className="flex gap-1 text-gray-900 items-center relative">
              <CiSquareCheck size="20" />
              <span>{prompt.name}</span>
              <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={({ top, left, className, onClose }, ref) => (
                  <Popover
                    full
                    ref={ref}
                    className={className}
                    style={{ top, left }}
                  >
                    <Dropdown.Menu
                      onSelect={async (eventKey) => {
                        onClose(200)

                        switch (eventKey) {
                          case 'edit':
                            onEdit &&
                              onEdit({
                                promptId: prompt.id,
                                meetingId,
                              })
                            break
                          case 'remove':
                            if (
                              window.confirm(
                                'Are you sure you want to delete this prompt?'
                              )
                            ) {
                              await deleteMeetingPromptOutput({
                                meetingId,
                                promptId: prompt.id,
                              })
                            }
                            break
                        }
                      }}
                    >
                      {!!prompt.promptUsers_aggregate.aggregate?.count && (
                        <Dropdown.Item eventKey="edit">
                          <CiEdit className="inline-block" />
                          Edit
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        eventKey="remove"
                        className="text-red-500 flex items-center"
                      >
                        <CiTrash className="inline-block" />
                        Remove
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                )}
              >
                <IconButton
                  style={{ padding: '4px', marginLeft: 'auto' }}
                  icon={<CiSettings />}
                  className="text-gray-600"
                />
              </Whisper>
            </h5>
          </Stack.Item>
          <Stack.Item grow={1}>
            <pre
              className="mt-1"
              style={{
                whiteSpace: 'pre-wrap',
                fontFamily: 'Manrope',
                overflowY: 'hidden',
              }}
            >
              {parsePromptOutput(output, Content_Type_Enum.TextPlain)}
              {finishedAt ? '' : <span className="pulse">●</span>}
            </pre>
          </Stack.Item>
        </Stack>
      ))}
    </>
  )
}
