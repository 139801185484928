import React from 'react'
import { Stack } from 'rsuite'

const Copyright = () => {
  return (
    <Stack
      className="copyright"
      justifyContent="center"
      style={{ height: 40, marginTop: 20 }}
    >
      <div className="container">
        <p className="text-xs">
          © 2023, Handcrafted with ❤️ by{' '}
          <a href="https://luni.ai" target="_blank" rel="noreferrer">
            luni.ai
          </a>
        </p>
      </div>
    </Stack>
  )
}

export default Copyright
