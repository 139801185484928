import React, { useEffect } from 'react'
import { Panel, Stack, Button, Breadcrumb } from 'rsuite'
import Copyright from '../../components/Copyright'
import {
  Integration_Type_Enum,
  useDeleteUserIntegrationMutation,
  useGetUserIntegrationsQuery,
  useInstallUserIntegrationMutation,
} from '../../generated/urql.user'
import { ZOOM_OAUTH_AUTHORIZATION_URL } from '../../const'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FaLink } from 'react-icons/fa6'
import { BsCheckLg } from 'react-icons/bs'
import { FaUnlink } from 'react-icons/fa'
import NavLink from '../../components/NavLink'
const url = new window.URL(ZOOM_OAUTH_AUTHORIZATION_URL)
url.search = new window.URLSearchParams([
  ['response_type', 'code'],
  ['client_id', process.env.REACT_APP_ZOOM_CLIENT_ID || ''],
  ['redirect_uri', process.env.REACT_APP_ZOOM_REDIRECT_URI || ''],
]).toString()

const AvailableIntegrations = [
  {
    type: Integration_Type_Enum.Zoom,
    logo: 'zoom.png',
    name: 'Zoom',
    description:
      'Zoom integration enables you to perform analysis on available meeting recordings',
  },
]

const IntegrationsPage = () => {
  const [{ data }, refetch] = useGetUserIntegrationsQuery({
    requestPolicy: 'network-only',
  })
  const [searchParams] = useSearchParams()
  const [, installUserIntegration] = useInstallUserIntegrationMutation()
  const navigate = useNavigate()
  const [, deleteUserIntegration] = useDeleteUserIntegrationMutation()

  useEffect(() => {
    if (!searchParams.has('code')) {
      return
    }

    installUserIntegration({
      type: Integration_Type_Enum.Zoom,
      payload: { code: searchParams.get('code') },
    })
      .then(() => {
        refetch()
        navigate('/integrations')
      })
      .catch(console.error)
  }, [searchParams])

  return (
    <Panel
      header={
        <Breadcrumb>
          <Breadcrumb.Item as={NavLink} to="/">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Integrations</Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      {AvailableIntegrations.map((integration) => {
        const userIntegration = data?.user_integration.find(
          (userIntegration) =>
            userIntegration.integrationType === integration.type
        )

        return (
          <Panel
            key={integration.type}
            shaded
            bordered
            bodyFill
            style={{ display: 'inline-block', width: 240 }}
          >
            <div
              style={{
                margin: '16px',
                background: `url('${integration.logo}') no-repeat center center`,
                backgroundSize: 'contain',
                height: '150px',
              }}
            />
            <Panel
              header={
                <Stack justifyContent="space-between">
                  <span>{integration.name}</span>{' '}
                </Stack>
              }
            >
              <p>
                <small>{integration.description}</small>
              </p>
              <Stack justifyContent="center" style={{ marginTop: '16px' }}>
                {!userIntegration && (
                  <Button
                    href={url.toString()}
                    appearance="primary"
                    color="green"
                  >
                    <FaLink />
                    &nbsp; Connect
                  </Button>
                )}
                {!!userIntegration && (
                  <Stack direction="column" spacing="8px">
                    <Stack justifyContent="center" alignItems="center">
                      <BsCheckLg size={24} color="green" />
                      <p style={{ color: 'var(--rs-green-500)' }}>Connected</p>
                    </Stack>
                    <Button
                      appearance="ghost"
                      color="red"
                      size="xs"
                      style={{ fontSize: '10px', padding: '0px 5px' }}
                      onClick={async () => {
                        const { error } = await deleteUserIntegration({
                          id: userIntegration.id,
                        })
                        if (error) {
                          console.error(error)
                          return
                        }

                        await refetch()
                      }}
                    >
                      <FaUnlink />
                      &nbsp;Disconnect
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Panel>
          </Panel>
        )
      })}
      <Copyright />
    </Panel>
  )
}

export default IntegrationsPage
