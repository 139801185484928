import { Content_Type_Enum } from '../../generated/urql.user'
import { jsonrepair } from 'jsonrepair'

export function parsePromptOutput(
  output: string,
  contentType: Content_Type_Enum.ApplicationJson
): object
export function parsePromptOutput(
  output: string,
  contentType: Content_Type_Enum.TextMarkdown | Content_Type_Enum.TextPlain
): string
export function parsePromptOutput(
  output: string,
  contentType: Content_Type_Enum
) {
  switch (contentType) {
    case Content_Type_Enum.ApplicationJson:
      return JSON.parse(jsonrepair(output))
    default:
      return output
  }
}
