import React, { useCallback, useRef } from 'react'
import {
  Dropdown,
  Popover,
  Whisper,
  WhisperInstance,
  Stack,
  Avatar,
} from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

const Header = () => {
  const trigger = useRef<WhisperInstance>(null)
  const { user, logout } = useAuth0()

  const logoutWithRedirect = useCallback(
    () =>
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      }),
    [logout]
  )

  return (
    <Stack className="header" spacing={8}>
      {!!user && (
        <Whisper
          placement="bottomEnd"
          trigger="click"
          ref={trigger}
          speaker={({ onClose, left, top, className }, ref) => {
            const handleSelect = (eventKey?: string) => {
              onClose()
              console.log(eventKey)
            }

            return (
              <Popover
                ref={ref}
                className={className}
                style={{ left, top }}
                full
              >
                <Dropdown.Menu onSelect={handleSelect}>
                  <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                    <p>Signed in as</p>
                    <strong>{user.name}</strong>
                  </Dropdown.Item>
                  <Dropdown.Item divider />
                  <Dropdown.Item onClick={logoutWithRedirect}>
                    Sign out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            )
          }}
        >
          <Avatar
            size="sm"
            circle
            src={user.picture}
            alt={user.name}
            style={{ marginLeft: 8 }}
          />
        </Whisper>
      )}
    </Stack>
  )
}

export default Header
