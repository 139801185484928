import React from 'react'
import ReactDOM from 'react-dom'
import history from './lib/history'
import App from './App'
import './index.css'
import './styles/index.less'
import { Auth0Provider } from '@auth0/auth0-react'
import { guessApiAddress } from './lib/client'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      onRedirectCallback={(appState) => {
        history.push(
          appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname
        )
      }}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: guessApiAddress(),
      }}
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
