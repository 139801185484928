import React, { ReactNode } from 'react'

type FeatureFlagProps = {
  children: ReactNode
  flag: FeatureFlags
}

export enum FeatureFlags {
  MeetingTypeEnabled = 'meeting-type-enabled',
}

const DefaultFeatureFlagValues: Record<FeatureFlags, boolean> = {
  [FeatureFlags.MeetingTypeEnabled]:
    process.env.REACT_APP_ENVIRONMENT === 'dev',
}

export const FeatureFlag: React.FC<FeatureFlagProps> = ({ children, flag }) => {
  return DefaultFeatureFlagValues[flag] ? <>{children}</> : null
}
