import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { getStorageUrl } from '../../lib/storage'
import { useGetMeetingVideoRecordingSubscription } from '../../generated/urql.user'
import { Loader } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

export type MeetingVideoRecordingType = {
  get currentTime(): number
  set currentTime(value: number)
}

const MeetingVideoRecording = forwardRef<
  MeetingVideoRecordingType,
  {
    meetingId: string
    onTimeUpdate?: (currentTime: number) => void
  }
>(({ meetingId, onTimeUpdate }, ref) => {
  const [{ data: getMeetingVideoRecordingData }] =
    useGetMeetingVideoRecordingSubscription({ variables: { meetingId } })
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>()

  useImperativeHandle(ref, () => ({
    get currentTime() {
      return (videoRef.current?.currentTime || 0) * 1000
    },
    set currentTime(value: number) {
      if (videoRef.current) {
        videoRef.current.currentTime = value / 1000
      }
    },
  }))

  useEffect(() => {
    let stop = false
    getAccessTokenSilently().then((accessToken) => {
      if (!stop) {
        setAccessToken(accessToken)
      }
    })

    return () => {
      stop = true
    }
  }, [getAccessTokenSilently, setAccessToken])

  const meetingVideo = getMeetingVideoRecordingData?.meeting_recording[0]

  if (!meetingVideo) {
    return <Loader />
  }

  return (
    <video
      ref={videoRef}
      controls
      src={getStorageUrl(meetingVideo.fileId, accessToken)}
      width="100%"
      height="200px"
      onTimeUpdate={() => {
        if (videoRef?.current) {
          if (onTimeUpdate) {
            onTimeUpdate(videoRef.current.currentTime * 1000)
          }
        }
      }}
    />
  )
})

export default MeetingVideoRecording
