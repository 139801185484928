import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import {
  Button,
  Divider,
  Drawer,
  Input,
  IconButton,
  Stack,
  Notification,
} from 'rsuite'
import {
  GetPromptByIdDocument,
  GetPromptByIdQuery,
  GetPromptByIdQueryVariables,
  useDeletePromptMutation,
  useGetMeetingPromptOutputSubscription,
  useInsertMeetingPromptMutation,
  useUpdatePromptMutation,
} from '../generated/urql.user'
import { useAuth0 } from '@auth0/auth0-react'
import { BsSend } from 'react-icons/bs'
import { TfiSave } from 'react-icons/tfi'
import { useClient } from 'urql'
import { Controller, useForm } from 'react-hook-form'

export type CustomPromptDrawerType = {
  show: (args: { promptId?: string; meetingId: string }) => void
}

const TemplateVariablesGlossary: React.FC<{
  onClick: (text: string) => void
}> = ({ onClick }) => (
  <table className="mt-8 text-xs text-gray-400">
    <thead>
      <tr>
        <th colSpan={2} className="text-left">
          Available template variables
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <Button
            appearance="link"
            size="xs"
            onClick={() => onClick('{{meeting.transcription}}')}
          >
            {'{{meeting.transcription}}'}
          </Button>
        </td>
        <td>Full meeting transcription</td>
      </tr>
      <tr>
        <td>
          <Button
            appearance="link"
            size="xs"
            onClick={() => onClick('{{meeting.topic}}')}
          >
            {'{{meeting.topic}}'}
          </Button>
        </td>
        <td>Meeting topic</td>
      </tr>
    </tbody>
  </table>
)

const CustomPromptDrawer = forwardRef<
  CustomPromptDrawerType,
  {
    onSelectedPrompt: (promptId: string) => void
  }
>(({ onSelectedPrompt }, ref) => {
  const { user } = useAuth0()
  const client = useClient()
  const [open, setOpen] = useState(false)
  const [promptId, setPromptId] = useState<string>()
  const [meetingId, setMeetingId] = useState<string>()
  const [generalError, setGeneralError] = useState<string>()
  const [, updatePrompt] = useUpdatePromptMutation()
  const [, insertMeetingPrompt] = useInsertMeetingPromptMutation()
  const [, deletePrompt] = useDeletePromptMutation()
  const [{ data: getMeetingPromptOutputData }] =
    useGetMeetingPromptOutputSubscription({
      variables: {
        meetingId,
        promptId,
      },
      pause: !promptId,
    })
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  const { control, watch, setValue, handleSubmit, reset } = useForm<{
    input: string
    saved: boolean
    name: string
  }>({
    defaultValues: {
      input: '',
      saved: false,
      name: 'Custom prompt',
    },
  })
  const promptOutput = getMeetingPromptOutputData?.meeting_prompt_output_by_pk

  useImperativeHandle(ref, () => ({
    show: ({ promptId, meetingId }) => {
      setMeetingId(meetingId)
      setPromptId(promptId)
      setOpen(true)
    },
  }))

  useEffect(() => {
    if (promptId) {
      client
        .query<GetPromptByIdQuery, GetPromptByIdQueryVariables>(
          GetPromptByIdDocument,
          { id: promptId }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.prompt_by_pk) {
            reset({
              input: data.prompt_by_pk.input,
              saved: data.prompt_by_pk.saved,
              name: data.prompt_by_pk.name,
            })
          }
        })
    } else {
      reset({
        input: '',
        saved: false,
        name: 'Custom prompt',
      })
    }
  }, [promptId, client, reset])

  function insertAtCursor(text: string) {
    const textarea = textareaRef.current
    if (!textarea) return

    const input = watch('input')

    const startPos = textarea.selectionStart
    const endPos = textarea.selectionEnd
    const textBefore = input.substring(0, startPos)
    const textAfter = input.substring(endPos, input.length)

    setValue('input', textBefore + text + textAfter)

    // Focus and set cursor position
    setTimeout(() => {
      textarea.focus()
      textarea.selectionStart = startPos
      textarea.selectionEnd = endPos - (endPos - startPos) + text.length
    }, 0)
  }

  const doSavePrompt = useCallback(
    async ({
      input,
      name,
      saved,
    }: {
      input: string
      name: string
      saved: boolean
    }) => {
      setGeneralError(undefined)

      console.log('doSave')

      if (!user?.sub) {
        throw new Error(`Missing user.sub`)
      }

      if (promptId) {
        console.log('updatePrompt')
        const { error } = await updatePrompt({
          promptId,
          input,
          name,
          saved,
        })

        if (error) {
          setGeneralError(error.message)
          return
        }
      } else {
        console.log('insertPrompt')
        const { data, error } = await insertMeetingPrompt({
          meetingId,
          input,
          name,
          saved,
          userId: user.sub,
        })

        if (error) {
          setGeneralError(error.message)
          return
        }

        if (data?.insert_prompt_one) {
          setPromptId(data.insert_prompt_one.id)
        }
      }
    },
    [insertMeetingPrompt, updatePrompt, user, meetingId, promptId]
  )

  const doDeletePrompt = useCallback(async () => {
    if (!promptId) return

    if (
      !window.confirm(
        'Are you sure you want to permanently delete this prompt and all it occurrences?'
      )
    )
      return

    await deletePrompt({ id: promptId })
    setOpen(false)
  }, [promptId, deletePrompt])

  return (
    <Drawer backdrop={true} open={open} onClose={() => setOpen(false)}>
      <Drawer.Header>
        <Drawer.Title>
          <Stack justifyContent="space-between">
            <Stack.Item grow={1}>
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input value={value} onChange={(value) => onChange(value)} />
                )}
                name="name"
              />
            </Stack.Item>
            {!watch('saved') && (
              <IconButton
                appearance="link"
                icon={<TfiSave className="mr-1" />}
                disabled={!promptOutput}
                onClick={async () => {
                  setValue('saved', true)
                  await handleSubmit(doSavePrompt)()
                  setOpen(false)
                }}
              >
                Save
              </IconButton>
            )}
          </Stack>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {generalError && (
          <Notification type="error" header="Unexpected error" closable>
            {generalError}
          </Notification>
        )}
        <pre
          style={{
            lineHeight: '120%',
            whiteSpace: 'pre-wrap',
            fontFamily: 'Manrope',
            overflowY: 'hidden',
          }}
        >
          {!!promptId && promptOutput?.output}
          {!promptOutput || promptOutput.finishedAt ? (
            ''
          ) : (
            <span className="pulse">●</span>
          )}
        </pre>
        <Divider />
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              as="textarea"
              rows={6}
              value={value}
              onChange={(value) => onChange(value)}
              placeholder="Type your prompt"
              ref={textareaRef}
            />
          )}
          name="input"
        />
        <Stack justifyContent="flex-end" className="mt-3">
          <Stack.Item>
            <IconButton
              appearance="link"
              onClick={handleSubmit(doSavePrompt)}
              endIcon={<BsSend />}
              disabled={!watch('input')}
            >
              {watch('saved') ? 'Update' : 'Test'}
            </IconButton>
          </Stack.Item>
        </Stack>
        <TemplateVariablesGlossary onClick={insertAtCursor} />
        {!!promptId && watch('saved') && (
          <Stack justifyContent="center" className="mt-6">
            <Button appearance="ghost" color="red" onClick={doDeletePrompt}>
              Remove prompt
            </Button>
          </Stack>
        )}
      </Drawer.Body>
    </Drawer>
  )
})

export default CustomPromptDrawer
