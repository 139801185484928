import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Container, Sidebar, Sidenav, Content, Nav, DOMHelper } from 'rsuite'
import { Link, Outlet } from 'react-router-dom'
import NavToggle from './NavToggle'
import Header from '../Header'
import Brand from '../Brand/index'
import { Branch, Dashboard, EventDetail, Gear, UserInfo } from '@rsuite/icons'

const { getHeight, on } = DOMHelper

export interface FrameProps {
  children?: React.ReactNode
}

const Frame = (props: FrameProps) => {
  const [expand, setExpand] = useState(true)
  const [windowHeight, setWindowHeight] = useState(getHeight(window))

  useEffect(() => {
    setWindowHeight(getHeight(window))
    const resizeListener = on(window, 'resize', () =>
      setWindowHeight(getHeight(window))
    )

    return () => {
      resizeListener.off()
    }
  }, [])

  const containerClasses = classNames('page-container', {
    'container-full': !expand,
  })

  const navBodyStyle: React.CSSProperties = expand
    ? { height: windowHeight - 112, overflow: 'auto' }
    : {}

  return (
    <Container className="frame">
      <Sidebar
        style={{ display: 'flex', flexDirection: 'column' }}
        width={expand ? 200 : 56}
        collapsible
      >
        <Sidenav.Header>
          <Brand />
        </Sidenav.Header>
        <Sidenav expanded={expand} defaultOpenKeys={['2', '3']}>
          <Sidenav.Body style={navBodyStyle}>
            <Nav>
              <Nav.Item
                eventKey="4-1"
                to="/meetings"
                icon={<EventDetail />}
                as={Link}
              >
                <span style={{ fontSize: '14px' }}>Meetings</span>
              </Nav.Item>
              <Nav.Item
                eventKey="4-2"
                to="/integrations"
                icon={<Branch />}
                as={Link}
              >
                <span style={{ fontSize: '14px' }}>Integrations</span>
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
        <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
      </Sidebar>

      <Container className={containerClasses}>
        <Header />
        <Content>
          <Outlet />
        </Content>
      </Container>
    </Container>
  )
}

export default Frame
