import React, { useEffect, useRef, useState } from 'react'
import { IconButton, SelectPicker } from 'rsuite'
import {
  useGetMeetingTypeDefinitionsQuery,
  useGetMeetingTypeSubscription,
  useUpdateMeetingTypeMutation,
} from '../../generated/urql.user'
import { CiEdit } from 'react-icons/ci'
import { TfiCheck, TfiClose } from 'react-icons/tfi'

export const MeetingType: React.FC<{ meetingId: string }> = ({ meetingId }) => {
  const [{ data: getMeetingTypeData, fetching: getMeetingTypeFetching }] =
    useGetMeetingTypeSubscription({
      variables: { meetingId },
    })
  const [
    {
      data: getMeetingTypeDefinitionsData,
      fetching: getMeetingTypeDefinitionsFetching,
    },
  ] = useGetMeetingTypeDefinitionsQuery()
  const [{ fetching: updateMeetingTypeFetching }, updateMeetingType] =
    useUpdateMeetingTypeMutation()
  const [editMode, setEditMode] = useState(false)
  const selectContainerRef = useRef<HTMLDivElement>(null)
  const [meetingTypeDefinitionId, setMeetingTypeDefinitionId] =
    useState<string>()

  const meetingType = getMeetingTypeData?.meeting_type[0]

  useEffect(() => {
    setMeetingTypeDefinitionId(meetingType?.meetingTypeDefinition.id)
  }, [meetingType])

  return (
    <>
      {!editMode && (
        <>
          {!meetingType && <span>--- not selected ---</span>}
          {!!meetingType && (
            <span>{meetingType?.meetingTypeDefinition.name}</span>
          )}
          <IconButton icon={<CiEdit />} onClick={() => setEditMode(true)} />
        </>
      )}
      {editMode && (
        <div ref={selectContainerRef}>
          <SelectPicker<string>
            loading={
              getMeetingTypeDefinitionsFetching ||
              updateMeetingTypeFetching ||
              getMeetingTypeFetching
            }
            size="sm"
            searchable
            cleanable={false}
            searchBy={(keyword, label, item) =>
              (item.label ? String(item.label) : '')
                .toLowerCase()
                .includes(keyword.toLowerCase()) ||
              item.description.toLowerCase().includes(keyword)
            }
            placeholder="Select meeting type"
            value={meetingTypeDefinitionId}
            onChange={(meetingTypeDefinitionId) => {
              setMeetingTypeDefinitionId(meetingTypeDefinitionId || undefined)
            }}
            data={
              getMeetingTypeDefinitionsData?.meeting_type_definition.map(
                ({ id, name, shortDescription, description }) => ({
                  label: name,
                  value: id,
                  description: shortDescription || description,
                })
              ) || []
            }
            renderMenuItem={(label, item) => (
              <div>
                <p className="font-sm font-bold m-0">{label}</p>
                <p className="text-xs text-muted m-0">
                  {item.description.substring(0, 100) + '...'}
                </p>
              </div>
            )}
          />
          <IconButton
            icon={<TfiCheck />}
            onClick={async () => {
              if (!meetingTypeDefinitionId) return
              if (
                window.confirm(
                  'Changing the meeting type will reset the existing analysis. Are you sure?'
                )
              ) {
                await updateMeetingType({
                  meetingId,
                  meetingTypeDefinitionId,
                })
                setMeetingTypeDefinitionId(meetingTypeDefinitionId)
                setEditMode(false)
              }
            }}
          />
          <IconButton
            icon={<TfiClose />}
            onClick={() => {
              setMeetingTypeDefinitionId(meetingType?.meetingTypeDefinition.id)
              setEditMode(false)
            }}
          />
        </div>
      )}
    </>
  )
}
