export function formatTime(ms: number) {
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  const secondsFormatted = seconds % 60
  const minutesFormatted = minutes % 60

  return (
    (days > 0 ? `${days}d ` : '') +
    `${hours}:${minutesFormatted.toString().padStart(2, '0')}:${secondsFormatted
      .toString()
      .padStart(2, '0')}`
  )
}
