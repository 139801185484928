import React, {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
  ReactNode,
  useCallback,
} from 'react'
import useResizeObserver from '@react-hook/resize-observer'

const Scale = 2

type PointProps = React.PropsWithChildren<{ time: number }>
export const Point: React.FC<PointProps> = ({ children }) => {
  return <>{children}</>
}

function isPointElement(
  element: ReactNode
): element is React.ReactElement<PointProps> {
  return (
    !!element &&
    typeof element === 'object' &&
    'props' in element &&
    'time' in element.props
  )
}

type DurationProps = React.PropsWithChildren<{
  startTime: number
  endTime: number
}>

export const Duration: React.FC<DurationProps> = ({ children }) => {
  return <>{children}</>
}

function isDurationElement(
  element: ReactNode
): element is React.ReactElement<DurationProps> {
  return (
    !!element &&
    typeof element === 'object' &&
    'props' in element &&
    'startTime' in element.props &&
    'endTime' in element.props
  )
}

type TimelineProps = {
  children: ReactNode
  onMouseOver?: (time: number) => void
  onMouseMove?: (time: number) => void
  onMouseOut?: (time: number) => void
  onMouseLeave?: (time: number) => void
  onClick?: (time: number) => void
  style?: CSSProperties
  className?: string
  maxTime?: number
}

function getMaxTime(children: TimelineProps['children']) {
  return (Array.isArray(children) ? children : [children]).reduce(
    (maxTime, child) => {
      if (isPointElement(child)) {
        return maxTime < child.props.time ? child.props.time : maxTime
      } else if (isDurationElement(child)) {
        return maxTime < child.props.endTime ? child.props.endTime : maxTime
      } else {
        return maxTime
      }
    },
    0
  )
}

const TimelineComponent: React.FC<TimelineProps> = ({
  children,
  onMouseOver,
  onMouseMove,
  onMouseOut,
  onMouseLeave,
  onClick,
  style,
  className,
  maxTime: fixedMaxTime,
}) => {
  const [width, setWidth] = useState(0)
  const maxTime = useMemo(
    () => (fixedMaxTime ? fixedMaxTime : getMaxTime(children)),
    [children, fixedMaxTime]
  )
  const ref = useRef<HTMLDivElement | null>(null)
  useResizeObserver(ref, (entry) => setWidth(entry.contentRect.width))

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth)
    }
  }, [setWidth, ref])

  const handleMouseEvent = useCallback(
    (eventHandler?: (time: number) => void) => (event: React.MouseEvent) => {
      if (!eventHandler) {
        return
      }

      if (!event.target) {
        return
      }

      const rect = event.currentTarget.getBoundingClientRect()
      eventHandler(
        Math.min(
          ((event.clientX - rect.left) / width) * Scale * maxTime,
          maxTime
        )
      )
    },
    [width, maxTime]
  )

  return (
    <div
      style={style}
      className={className}
      onMouseOver={(event) => {
        handleMouseEvent(onMouseOver)
      }}
      onMouseMove={(event) => {
        handleMouseEvent(onMouseMove)(event)
      }}
      onMouseOut={(event) => {
        handleMouseEvent(onMouseOut)(event)
      }}
      onMouseLeave={(event) => {
        handleMouseEvent(onMouseLeave)(event)
      }}
      onClick={(event) => {
        handleMouseEvent(onClick)(event)
      }}
    >
      <div
        ref={ref}
        style={{
          position: 'relative',
          transform: 'translate(-25%, -25%) scale(0.5)',
          width: '200%',
          height: '200%',
        }}
      >
        {(Array.isArray(children) ? children : [children]).map((child, i) => {
          if (isPointElement(child)) {
            const left = (child.props.time / maxTime) * width
            return (
              <div
                key={i}
                style={{
                  position: 'absolute',
                  left: `${left}px`,
                  top: '50%',
                  marginTop: '-6px',
                }}
              >
                {child}
              </div>
            )
          } else if (isDurationElement(child)) {
            const l = (child.props.startTime / maxTime) * width
            const w = (child.props.endTime / maxTime) * width - l
            return (
              <div
                key={i}
                style={{
                  position: 'absolute',
                  left: `${l}px`,
                  width: `${w}px`,
                  top: '50%',
                  marginTop: '-6px',
                }}
              >
                {child}
              </div>
            )
          } else {
            return child
          }
        })}
      </div>
    </div>
  )
}

export const Timeline = Object.assign(TimelineComponent, { Point, Duration })
