import React from 'react'

interface LogoProps {
  width?: number
  height?: number
  className?: string
  style?: React.CSSProperties
}

export default function Logo({
  width,
  height,
  style,
  className = '',
}: LogoProps) {
  const styles = { width, height, display: 'inline-block', ...style }
  return (
    <div
      style={styles}
      className={`rsuite-logo logo-animated logo-animated-delay-half-seconds bounce-in ${className} `}
    >
      <div
        style={{
          width: `${width || 30}px`,
          height: `${height || 30}px`,
          background: "url('/luni-logo.png') center center",
          backgroundSize: 'contain',
        }}
      />
    </div>
  )
}
